import axios from 'axios';
import { Helmet, HelmetProvider } from "react-helmet-async";
import React from 'react'
import ArticleSnippetLarge from './ArticleSnippetLarge';
import ShowMore from './ShowMore';
import { parseFloatToUSString } from '../utils';
import ArticleSnippet from './ArticleSnippet';
import { NewsSuggestion } from './SearchElement';

function generateHeadline(reportingDate, shares, sharePrice, insiderName, transactionType, currency) {
  let verb = transactionType === 'P' ? 'Purchases' : 'Bought';
  return `${insiderName} ${verb} ${shares} Shares at ${currency}${sharePrice}/Share on ${reportingDate}`
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.maxHeadLines = 300;

    this.state = {
      news: [],
      newsLoaded: false,
      initialLoading: true,
      start: 0,
      end: 10,
      hasMore: true,
      loadingNews: false, // Separate loading state for news

      transactions: [],
      transactionsLoaded: false,
      transactionsStart: 0,
      transactionsEnd: 10,
      hasMoreTransactions: true,
      loadingTransactions: false, // Separate loading state for transactions
    };

    this.filter = this.filter.bind(this);
    this.showMoreItems = this.showMoreItems.bind(this);
    this.showMoreTransactions = this.showMoreTransactions.bind(this);
  }

  componentDidMount() {
    this.fetchNews(this.state.start, this.state.end);
    this.fetchTransactions(this.state.transactionsStart, this.state.transactionsEnd);
  }

  fetchNews(start, end) {
    this.setState({ loadingNews: true }); // Set loadingNews to true
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/items/news?start=${start}&end=${end}`)
      .then(res => {
        const newNews = res.data;
        newNews.map(newsItem => (this.props.registerSearchElement(new NewsSuggestion(newsItem.l, newsItem.t, newsItem.c))));
        this.setState(prevState => ({
          news: [...prevState.news, ...newNews],
          newsLoaded: true,
          hasMore: newNews.length === (end - start),
          loadingNews: false, // Set loadingNews to false on successful fetch
          initialLoading: false
        }));
      })
      .catch(err => {
        console.log(err);
        this.setState({ loadingNews: false }); // Set loadingNews to false on error
      });
  }

  fetchTransactions(start, end) {
    this.setState({ loadingTransactions: true }); // Set loadingTransactions to true
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/v1/items/insider?start=${start}&end=${end}`)
      .then(res => {
        const newTransactions = res.data;
        this.setState(prevState => ({
          transactions: [...prevState.transactions, ...newTransactions],
          transactionsLoaded: true,
          hasMoreTransactions: newTransactions.length === (end - start),
          loadingTransactions: false, // Set loadingTransactions to false on successful fetch
        }));
      })
      .catch(err => {
        console.log(err);
        this.setState({ loadingTransactions: false }); // Set loadingTransactions to false on error
      });
  }

  showMoreItems() {
    const { end } = this.state;
    const newStart = end;
    const newEnd = end + 20;
    this.setState({ start: newStart, end: newEnd }, () => {
      this.fetchNews(this.state.start, this.state.end);
    });
  }

  showMoreTransactions() {
    const { transactionsEnd } = this.state;
    const newStart = transactionsEnd;
    const newEnd = transactionsEnd + 20;
    this.setState({ transactionsStart: newStart, transactionsEnd: newEnd }, () => {
      this.fetchTransactions(this.state.transactionsStart, this.state.transactionsEnd);
    });
  }

  filter(i, k) {
    // Implement your filter logic if needed
  }

  render() {
    const { news, transactions, loadingNews, loadingTransactions, hasMore, hasMoreTransactions, initialLoading } = this.state;

    return (
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Financial Coffee | Financial news that drive global markets</title>
          <meta name="description"
            content="We keep you updated with the latest in finance and the stock market. We bring you fresh news from top financial sources, plus the latest bank press releases, monetary policy announcements, and insider transactions. Track insider buying and selling activities to gain insights into corporate confidence and potential market movements—all in one place."
            data-rh="true" />
          <link rel="canonical" href="%PUBLIC_URL%" />
        </Helmet>
        <div className="">
          {/* Helmet and meta tags */}
          <div className='center-readable c-read-h-margins'>
            <div className='news-container'>
              <h1>What's going on?</h1>
              {/* Selector component */}
              {initialLoading && loadingNews && [...Array(10)].map((_, i) => (
                <ArticleSnippetLarge
                  key={i}
                  id={i}
                  title="Fetching..."
                  link="#"
                  category="Fetching"
                  content="Fetching..."
                  date={new Date()}
                />
              ))}
              {/* Render news articles */}
              {news.slice(0, this.maxHeadLines).map(release => (
                <ArticleSnippetLarge
                  key={release.id}
                  id={release.id}
                  title={release.t}
                  link={release.l}
                  category={release.c}
                  content={release.b}
                  date={release.pd}
                  thumbnail={release.i}
                  tickers={release.mt}
                />
              ))}
              {/* ShowMore for news */}
              <ShowMore onClick={this.showMoreItems} hasMore={hasMore} loading={loadingNews} />
              <h1>Insider transaction activity</h1>
              {/* Loading state for transactions */}
              {initialLoading && loadingTransactions && [...Array(10)].map((_, i) => (
                <ArticleSnippet
                  key={i}
                  id={i}
                  title="Fetching..."
                  link="#"
                  category="Fetching"
                  content="Fetching..."
                  date={new Date()}
                />
              ))}
              {/* Render transaction articles */}
              {transactions
                .filter(transaction => transaction.tt === 'P' || transaction.tt === 'S')
                .slice(0, this.maxHeadLines)
                .map((transaction, index) => (
                  <ArticleSnippet
                    key={`transaction-${transaction.rd}-${index}`} // Using a unique key combining transaction id and index
                    id={`transaction-${transaction.rd}-${index}`} // Using a unique id combining transaction id and index
                    title={generateHeadline(
                      transaction.td,
                      parseFloatToUSString(transaction.a, 0),
                      parseFloatToUSString(transaction.p),
                      transaction.o,
                      transaction.tt,
                      transaction.c
                    )}
                    link={`/inspect/${transaction.t}`}
                    category={transaction.com}
                    content={transaction.b}
                    date={transaction.rd}
                    tickers={transaction.t}
                  />
                ))}
              {/* ShowMore for transactions */}
              <ShowMore onClick={this.showMoreTransactions} hasMore={hasMoreTransactions} loading={loadingTransactions} />
            </div>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default Home;
