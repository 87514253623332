import './styles/variables.css';
import './styles/elements.css';
import './App.css';
import React from 'react'
import { Routes, Route, BrowserRouter, Link } from "react-router-dom";
import Home from './components/Home';
import BaseLayout from './components/BaseLayout';
import Banks from './components/Banks';
import ArticleSite from './components/ArticleSite';
import InsiderTransactions from './components/InsiderTransactions';
import InspectCompany from './components/InspectCompany';
import Disclaimer from './components/Disclaimer';
import Regulatory from './components/Regulatory';
import { CompanySuggestion } from './components/SearchElement';
import CHcompaniesData from './searchData/swiss_companies_new.json';
import UScompaniesData from './searchData/tickers2.json';
import Switzerland3ATopUp from './articles/Switzerland3ATopUp';
import ArticleFeeCompound from './articles/TheFeeTrap';

class App extends React.Component {

  constructor(props) {
    super(props);

    const swiss_companies = CHcompaniesData.map(company => {
      return new CompanySuggestion(`/inspect/${company.Id}`, company.Name, company.Ticker);
    });

    const us_companies = Object.keys(UScompaniesData).map(key => {
      const company = UScompaniesData[key];
      return new CompanySuggestion(`/inspect/${company.ticker}`, company.title, company.ticker);
    });



    const companies = swiss_companies.concat(us_companies)

    this.state = {
      allSuggestions: companies,
      searchSuggestions: [],
    };
  }

  registerSearchElement = (suggestion) => {
    this.setState(prevState => {
      const combinedSuggestions = [...prevState.allSuggestions, suggestion];
      return { allSuggestions: combinedSuggestions };
    });
  };

  // Function to update suggestions
  updateSuggestions = (newSuggestions) => {
    this.setState(prevState => {
      // Combine existing and new suggestions, then filter duplicates
      const combinedSuggestions = [...prevState.allSuggestions, ...newSuggestions];
      const uniqueSuggestions = Array.from(new Set(combinedSuggestions.map(s => s.displayText)))
        .map(displayText => {
          return combinedSuggestions.find(s => s.displayText === displayText);
        });

      return { allSuggestions: uniqueSuggestions };
    });
  };

  // Function to handle search logic
  handleSearch = (query) => {
    console.log(this.state.allSuggestions)
    if (query.length >= 3) {
      console.log('serahc is', query)
      // Filter allSuggestions based on the query
      const suggestions = this.state.allSuggestions.filter(item =>
        item.title.toLowerCase().includes(query.toLowerCase())
        // item.displayText.toLowerCase().includes(query.toLowerCase())
      );
      this.setState({ searchSuggestions: suggestions });
    } else {
      this.setState({ searchSuggestions: [] });
    }
  };

  // Function to clear suggestions
  handleClearSuggestions = () => {
    this.setState({ searchSuggestions: [] });
  };

  // Function to handle suggestion click
  handleSuggestionClick = (suggestion) => {
    console.log('Suggestion clicked:', suggestion);
    // Add logic to handle suggestion click (e.g., navigate to suggestion URL)
  };

  FourOFour() {
    return (
      <div className='defs center-readable c-read-h-margins'>
        <h1>Sorry, no money here.</h1>
        <p><Link className='minimal-anchor' to="/">Shall we go back to the home page</Link>?</p>
      </div>
    );
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="/" element={<BaseLayout
              onSearch={this.handleSearch}
              onSuggestionClick={this.handleSuggestionClick}
              onClearSuggestions={this.handleClearSuggestions}
              searchSuggestions={this.state.searchSuggestions}
              updateSuggestions={this.updateSuggestions}
            />}>
              <Route index element={<Home registerSearchElement={this.registerSearchElement} />} />
              <Route path="switzerland-retroactive-top-up-pillar-3a-contributions-2025" element={<Switzerland3ATopUp />} />
              <Route path="fee-trap-how-tiny-costs-sabotage-big-gains" element={<ArticleFeeCompound />} />
              <Route path="disclaimer" element={<Disclaimer />} />
              <Route path="insider" element={<InsiderTransactions />} />
              <Route path="banks" element={<Banks />} />
              <Route path="regulatory" element={<Regulatory />} />
              <Route path="inspect/:company" element={<InspectCompany />} />
              <Route path=":slug" element={<ArticleSite />} />
              <Route path="*" element={<this.FourOFour />} />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}
export default App;

